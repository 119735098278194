import loadable from '@loadable/component'
import React from "react"
import { Button } from "react-bootstrap"
import getappscreen from "../../assets/images/getappscreen.png"
import "../../assets/styles/actuate-minds.scss"
const EducationPartner = loadable(() => import('../layout/modal'))

const ActuateMinds = () => {
  const [modalShow, setModalShow] = React.useState(false)
  return (
    <>
      <div className="phone-secion mt-4">
        <div className="container d-flex">
          <div className="col-md-6 px-md-4 pb-md-2">
            <h2 className="fnt-lg-32 fnt-28 fnt-bold fnt-lg-bold text-clr54 mt-md-5 pb-md-5 text-center text-lg-start">
              Get exclusive app for your school
            </h2>
            <div className="d-flex d-lg-none justify-content-center">
              <img
                className="mobile-mockup"
                src={getappscreen}
                alt="mobile-getappscreen"
              />
            </div>
            <p className="fnt-lg-22 fnt-18 fnt-bold fnt-lg-bold text-clr54 mx-2 mx-md-0 mb-4 pb-md-5">
              When your school becomes our education partner, we deliver a
              custom personalised app only for your school.
            </p>
            <div className="mx-auto mx-lg-0 w-fit-content mb-2 mb-md-0">
              <Button
                className="education-btn fnt-60 fnt-md-20 fnt-500 text-white w-100"
                onClick={() => setModalShow(true)}
              >
                Become our Education Partner
              </Button>

              <EducationPartner
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </div>
          <div className="col-md-6 d-none d-lg-block d-lg-flex justify-content-center">
            <img
              className="mobile-mockup"
              src={getappscreen}
              alt="mobile-getappscreen"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ActuateMinds
